import { css } from 'emotion';
import { Desktop, Mobile } from 'assets/css/breakpoints';

export const search = css({
  border: 'none !important',
  marginTop: '1.875em',
});

export const loadMoreBtn = css({
  [Mobile]: {
    margin: 'auto',
    marginBottom: '2.1875em',
    width: '100%',
    '& span': {
      margin: 'auto',
    },
  },
  marginBottom: '2.5em',
});

export const searchResultWrapper = css({
  borderRadius: '10px !important',
});

export const uploadBtn = theme =>
  css({
    paddingLeft: '0.625em',
    borderRadius: 3,
    padding: '0.625em',
    color: theme.gray200,
    backgroundColor: theme.gray600,
    '& .click-here-lbl': { fontSize: '0.625rem', color: theme.gray100 },
    '& .attach-text-lbl': { fontSize: '0.625rem', color: theme.gray200 },
  });

export const noMessagesBox = css({
  textAlign: 'center',
  '& .date': { fontSize: '0.75rem' },
  '& img': { marginTop: '4.375em' },
  '& .start': { fontSize: '1.125rem', fontWeight: 700, marginBottom: 0 },
  '& .ask-anything': { fontSize: '0.875rem', marginBottom: '3.75em' },
});

export const participantSelector = theme =>
  css({
    marginTop: '1.25em',
    display: 'flex',
    flexDirection: 'column',
    padding: '1.25em 1.25em 0 1.25em',
    '& p': { margin: '0 0 0 10px', fontSize: '1rem' },
    '& .wrapper': { alignItems: 'center', marginBottom: '1.0625em' },
    '& .popover': { justifyContent: 'flex-end' },
    '& .more-btn': { border: 'none', background: theme.white, fontSize: '1.375rem', padding: 0 },
  });

export const messageWrapper = (theme, isCurrentUser) =>
  css({
    verticalAlign: 'middle',
    marginBottom: '1.25em',
    '& .reverse-avatar ': { display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' },
    '& .message ': {
      backgroundColor: isCurrentUser ? theme.skyblue : theme.gray550,
      borderRadius: '2px',
      padding: '0.5em 0.75em',
      marginTop: '0.25em',
    },
    '& .image ': { marginTop: '0.5em' },
    '& .user-name-label ': { marginRight: '0.3125em', marginLeft: '0.3125em', fontSize: '0.8125rem', fontWeight: 500 },
    '& .hour-label ': { fontSize: '0.6875rem', color: theme.gray200, display: 'flex', alignItems: 'center' },
  });

export const galleryModal = theme =>
  css({
    '& .ant-modal-content': {
      [Desktop]: { padding: '0 4.625em' },
      [Mobile]: { padding: '0 0.25em' },
    },
    '& .ant-modal-body': { padding: '0.625em 0' },
    '& .image-gallery-slide': {
      width: '100%',
      borderRadius: '8px',
      overflow: 'hidden',
      [Desktop]: { height: '40rem' },
      [Mobile]: { height: '30rem' },
    },
    '& .image-gallery-content .image-gallery-slide .image-gallery-image': {
      width: '100%',
      height: '100%',
      position: 'fixed',
    },
    '& .image-gallery-right-nav': {
      padding: 0,
      filter: 'none',
      right: '-3.4375em',
      [Mobile]: { right: 0 },
      '& svg': {
        color: theme.gray200,
        height: '3.125rem',
        width: '1.875rem',
      },
    },
    '& .image-gallery-left-nav': {
      filter: 'none',
      padding: 0,
      left: '-3.4375em',
      [Mobile]: { left: 0 },
      '& svg': {
        color: theme.gray200,
        height: '3.125rem',
        width: '1.875rem',
      },
    },
  });

export const allMessagesWrapper = css({
  maxHeight: '28.125rem',
  paddingRight: '0.625em',
  overflowY: 'auto',
  display: 'flex !important',
  flexDirection: 'column-reverse',
  '& .date-label ': { fontSize: '0.75rem', display: 'flex', justifyContent: 'center' },
  '& .ant-row ': { flexDirection: 'column-reverse' },
  '& .reverse ': { flexDirection: 'column-reverse', display: 'flex' },
});

export const imagesPreview = css({
  '& .ant-upload-list-item.ant-upload-list-item-done': {
    padding: '0 ',
    border: 'none',
  },
  '& .ant-upload-list-item-image': { objectFit: 'cover !important' },
  '& .ant-upload-list-item-info': { borderRadius: 3 },
  '& .ant-upload-list-picture-card-container': {
    borderRadius: '4px !important',
    [Desktop]: { width: '4.8125rem', height: '4.25rem' },
    [Mobile]: { width: '6.25rem', height: '5rem' },
  },
});

export const blackTextLink = theme =>
  css({
    color: theme.gray100,
  });

export const moreBtn = theme =>
  css({
    fontSize: '1.375rem',
    color: theme.gray100,
  });

export const backButton = theme =>
  css({
    color: theme.gray100,
    fontSize: '1.1875rem',
    fontWeight: 700,
  });
