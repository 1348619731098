import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { isNil } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { useLocations } from '@oneecosystem/dealshaker-core';
import Slider from 'react-slick';
import { isDesktop } from 'react-device-detect';
import FeatherIcon from 'feather-icons-react';
import { CarouselSkeleton } from 'components/ui';
import { useDeepEffect } from 'components/UIExternal';
import FeaturedDeal from 'components/deal/FeaturedDeal';
import { dealsSliderType } from 'enums';
import useError from 'services/errorHandling/useError';
import { getRandomDeals } from 'services/api/dealsService';
import { title, contentWrapper, featuredDealsPanel, arrowButton } from './styles';

const arrowSettings = theme => ({
  size: 40,
  className: arrowButton,
  stroke: theme.gray200,
  strokeWidth: 1,
});

const arrowGeneral = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: isDesktop,
};

const FeaturedDeals = ({ categoryName }) => {
  const { shippingTo, shippingFrom } = useLocations();
  const { getText } = useTranslations();
  const theme = useTheme();
  const { setError } = useError();
  const [deals, setDeals] = useState(null);

  useDeepEffect(() => {
    fetchDeals();
  }, [shippingFrom, shippingTo]);

  const fetchDeals = async () => {
    const [res, err] = await getRandomDeals({
      from: shippingFrom?.map(el => el?.id),
      to: shippingTo?.map(el => el?.id),
      promotionType: dealsSliderType?.featured,
      pageSize: 5,
    });
    err ? setError(err) : setDeals(res?.items);
  };

  const arrow = position => (
    <div>
      <FeatherIcon icon={`chevron-${position}`} {...arrowSettings(theme)} />;
    </div>
  );

  const settings = {
    ...arrowGeneral,
    nextArrow: arrow('right', theme),
    prevArrow: arrow('left', theme),
  };

  return (
    <div className={`${featuredDealsPanel(theme)} featured-on-dealshaker`}>
      <div className={`${contentWrapper} content-container`}>
        <h4 className={title(theme)}>
          {categoryName
            ? getText('featuredInCategory', { category: getText(categoryName) })
            : getText('featuredOnDealShaker')}
        </h4>
        <div className={featuredDealsPanel(theme)}>
          <div className={`${contentWrapper} content-container`}>
            {isNil(deals) && <CarouselSkeleton />}
            <Slider {...settings}>
              {deals?.map(deal => (
                <FeaturedDeal key={deal.id} deal={deal} />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

FeaturedDeals.propTypes = {
  categoryName: PropTypes.string,
};

export default FeaturedDeals;
