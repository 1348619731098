import { css, cx } from 'emotion';

export const dealContainer = theme =>
  cx(
    css({
      fontWeight: 400,
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '100%',
      padding: 0,
      minHeight: '11rem',
      wordSpacing: 'none',

      '& .deal-thumbnail-container': {
        position: 'relative',
        borderRadius: '0.25rem',
        marginBottom: '1.5em',
        width: '100%',
      },

      '& .deal-thumbnail': {
        width: '100%',
        display: 'inline',
        aspectRatio: ' 1 / 1',
        objectFit: 'cover',
        borderRadius: 4,
      },

      '& .deal-title': {
        color: theme.gray100,
        width: '100%',
        whiteSpace: 'normal',
        marginBottom: '1em',
      },

      '& .deal-price-panel': {
        color: theme.gray100,
        marginBottom: '0.5em',
      },
    }),
    'deal-button-container',
  );

export const dealDiscountStamp = css({
  top: '0.5em',
  left: '0.5em',
  height: '2.5rem',
  width: '2.5rem',
});

export const thumbnailTag = css({
  position: 'absolute',
  bottom: '0.25rem',
  left: '0.25rem',
});

export const favoriteIcon = (isFavored, theme) =>
  css({
    position: 'absolute',
    bottom: '0.3125em',
    right: '0.3125em',
    zIndex: 100,
    padding: 0,
    WebkitTextFillColor: isFavored ? theme.red : theme.white,
    WebkitTextStrokeWidth: 1,
    WebkitTextStrokeColor: isFavored ? theme.white : theme.gray300,
  });

export const dateAvailableLabel = theme =>
  css({
    color: theme.primary_orange,
    fontSize: '0.75rem',
  });

export const starRatings = theme =>
  css({
    '&.ant-rate': {
      color: theme.gray100,
      fontSize: '0.75rem',
      marginRight: '1em',
    },
  });

export const ratingsCountLink = theme =>
  css({
    fontSize: '0.8125em',
    color: theme.gray300,
    textDecoration: 'underline',
    padding: 0,
  });

export const ratingsCountLabel = theme =>
  css({
    fontSize: '0.8125rem',
    color: theme.gray300,
  });

export const dealCategoryContainer = css({
  display: 'flex',
  width: '100%',
  flexFlow: 'column wrap',
  gap: '0.5em',
});

export const categoryLabel = css({
  display: 'inline-block',
  fontSize: '0.75rem',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});
