import React from 'react';
import { isTokenValid } from '@oneecosystem/authenticate';
import FeaturedDeals from 'components/deal/FeaturedDealsPanel';
import {
  BestSellingDeals,
  DealsOfTheDay,
  PreviousInterestsDeals,
  TopCategoriesPanel,
  RecommendedDeals,
} from './components';
import { pageContent } from './styles';

const HomePage = () => (
  <div className={pageContent}>
    <FeaturedDeals />
    <RecommendedDeals />
    <DealsOfTheDay />
    <TopCategoriesPanel />
    <BestSellingDeals />
    {isTokenValid() && <PreviousInterestsDeals />}
  </div>
);

export default HomePage;
