import { css } from 'emotion';
import { Mobile } from '../../../assets/css/breakpoints';

export const featuredDealsPanel = theme =>
  css({
    backgroundColor: theme.gray600,
  });

export const contentWrapper = css({
  [Mobile]: {
    marginTop: '-2em',
    '& > h4': {
      paddingBottom: '1.5em',
    },
  },
  paddingBottom: '2em',
});

export const title = theme =>
  css({
    color: theme.background100,
    fontSize: '1.75rem',
    fontStyle: 'normal',
    fontWeight: 500,
  });

export const arrowButton = css({
  position: 'absolute',
  top: '50%',
  cursor: 'pointer',
  zIndex: 900,
  left: '-10px',
});
