import { css } from 'emotion';
import { Mobile } from 'assets/css/breakpoints';

export const headingPanel = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '1em',
});

export const headingTitle = css({
  [Mobile]: { fontSize: '1.1875rem' },
  fontWeight: 700,
});

export const wrapper = hideClones =>
  css({
    '& .slick-cloned': { display: hideClones && 'none !important' },
    '& .slick-track': { margin: hideClones && '0 !important' },
    '& .slick-list': {
      margin: '0 -0.2em',
      padding: '0px !important',
    },
    '& .slick-arrow:before': {
      display: 'none',
    },
    '& .row': {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      margin: '0 -1em',
      [Mobile]: {
        flexDirection: 'column',
        gap: '2em',
      },
    },
    '& .col': {
      flex: '1 0 0%',
      width: '100%',
      maxWidth: '100%',
      padding: '0 1em',
    },
  });

export const arrowButton = position =>
  css({
    position: 'absolute',
    top: '10%',
    cursor: 'pointer',
    zIndex: 900,
    margin: position === 'right' ? '0 0 0 3.125em' : '0 0 0 -3.125em',
    padding: '1em !important',
    left: position === 'left' ? '-5px' : '-12px',
  });
