import React from 'react';
import { useTheme } from 'emotion-theming';
import { Link } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import logo from 'assets/images/Logo_color.png';
import { scrollToTop } from 'utils';
import LocalizationPanel from '../LocalizationPanel';
import FooterStatistics from './FooterStatistics';

import { footer, footerContent, footerLogo, footerStatisticContainer } from './styles';

const Footer = () => {
  const theme = useTheme();
  const { getText } = useTranslations();

  return (
    <footer className={footer(theme)}>
      <div className="content-container">
        <div className={footerContent(theme)}>
          <Link to="/" className={footerLogo}>
            <img src={logo} alt="Logo" />
          </Link>
          <div className={footerStatisticContainer}>
            <FooterStatistics />
            <div className="footerAbout">
              <p>{getText('about')}</p>
              <div>
                <Link to="/brand-guide" onClick={scrollToTop}>
                  {getText('brandGuide')}
                </Link>
              </div>
              <div>
                <Link to="/our-global-structure">{getText('globalStructure')}</Link>
              </div>
              <div>
                <Link to="/merchant-list">{getText('merchantList')}</Link>
              </div>
            </div>
          </div>
          <LocalizationPanel />
        </div>
        <div>
          <div>
            © 2018-2024 Copyright by DEALSHAKER GLOBAL PTE. LTD. Head Office: 60 PAYA LEBAR ROAD, #11-53, PAYA LEBAR
            SQUARE, SINGAPORE (409051)
            <div>
              Responsible for Operation:{' '}
              <a className="footer-copyRight" href="mailto:office-hk@dealshaker.com">
                Mrs. Nguyen Thi Mai Lan (CEO){' '}
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
