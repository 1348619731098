import { injectGlobal, css } from 'emotion';
import 'assets/icons/line-awesome/line-awesome.css';
import 'assets/icons/dealShaker/dealshaker.css';
import '../../../node_modules/react-image-gallery/styles/css/image-gallery.css';
import 'antd/dist/antd.css';
import 'antd/es/modal/style';
import 'antd/es/slider/style';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Mobile, Desktop } from 'assets/css/breakpoints';

injectGlobal`
@import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Tagalog&display=swap');

:root {
  font-size: 16px;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  margin-right: calc(100% - 100vw);
  height: 100vh;
  margin: 0;
  padding: 0
}

body {
  font-weight: 400;
  font-size: 0.85rem;
  color: #222222;
  overflow-x: hidden;
  height: 100vh;
  margin: 0;
  padding: 0
}

* {
  font-family: Roboto, Noto Sans Tagalog, sans-serif;
  box-sizing:border-box;
  outline: none;
  margin: 0;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body p,body figure,body h1,body h2,body h3,body h4,body h5,body h6{
  margin: 0;
}

.default-font {
  font-family: "Roboto", sans-serif !important;
}

a{background-color:transparent}
body a{color:inherit;text-decoration:none}
body a:hover{color:inherit}
a:active,a:hover{outline:0}
a:focus,a:hover{text-decoration:none}
a:focus{outline:0}
h1, h2, h3, h4, h5, h6{font-weight:500}
h1{font-size:2rem}
h2{font-size:1.75rem}
h3{font-size:1.5rem}
h4{font-size:1.25rem}
h5{font-size:1rem}
h6{font-size:0.8rem}
img{border:0; display: block; max-width: 100%;}
hr{-webkit-box-sizing:content-box;-moz-box-sizing:content-box;box-sizing:content-box;height:0}
pre{overflow:auto}
code,kbd,pre,samp{font-family:monospace,monospace;font-size:1em}
button,input,optgroup,select,textarea{color:inherit;font:inherit;}
button{overflow:visible}
button,select{text-transform:none}
button,html input[type=button],input[type=reset],input[type=submit]{-webkit-appearance:button;cursor:pointer}
button[disabled],html input[disabled]{cursor:not-allowed}
button::-moz-focus-inner,input::-moz-focus-inner{border:0; padding:0}
button label{cursor: inherit;}
input{line-height:normal}
input[type=checkbox],input[type=radio]{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;padding:0}
input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button{height:auto}
input[type=search]{-webkit-appearance:textfield;-webkit-box-sizing:content-box;-moz-box-sizing:content-box;box-sizing:content-box}
input[type=search]::-webkit-search-cancel-button,input[type=search]::-webkit-search-decoration{-webkit-appearance:none}
textarea{overflow:auto}
ul,ol,dl{margin-bottom:0;margin-top:0;margin:0;list-style:none;}
p,h1,h2,h3,h4,h5,h6{box-sizing:border-box; outline: none; margin: 0; margin-bottom: 0; margin-top: 0; margin-left: 0; margin-right: 0;  padding: 0;}
button,input,select,textarea{font-family:inherit;font-size:inherit;line-height:inherit}
.img-responsive{display: block;max-width:100%;height:auto;}
hr{border:0; border-top:1px solid #eee}
[role=button]{cursor:pointer;}
.content-container {
  max-width: 1268px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}
.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-center {
  align-items: center;
  justify-content: center;
}
.flex-end {
  justify-content: flex-end;
}
.flex-1 {
  flex: 1
}
.space-between {
  justify-content: space-between;
}
.alignSelfFlexEnd {
  align-self: flex-end
}
.alignTextRight {
  text-align: right
}
.alignTextCenter {
  text-align: center
}
.section-title {
  font-size: 28px;
  line-height: 36px;
  margin-bottom: 6px;
}
.section-subtitle {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 10px;
}
.bold-text {
  font-weight: 500;
}
.bolder-text {
  font-weight: 700;
}
.separator {
  width: 1px;
  height: 16px;
  background-color: #DCDDE0;
  display: inline-block;
  margin-top: 1px;
  margin-bottom: 1px;
  margin-right: 8px;
  margin-left: 8px;
  vertical-align: middle;
}
.block-form-item {
 .ant-row.ant-form-item {
   display: block;
 }
}

.fullWidth {
  width: 100%
}
button > [class^="icon-"]::before,
button > [class*=" icon-"]::before {
  vertical-align: initial;
}
.relative {
  position: relative;
}

--- leaflet map styles ---
/* required styles */
.leaflet-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-container,
.leaflet-pane > svg,
.leaflet-pane > canvas,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
	position: absolute;
	left: 0;
	top: 0;
}

.leaflet-container {
	overflow: hidden;
}

.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
	-webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

/* Prevents IE11 from highlighting tiles in blue */
.leaflet-tile::selection {
	background: transparent;
}

/* Safari renders non-retina tile on retina better with this, but Chrome is worse */
.leaflet-safari .leaflet-tile {
	image-rendering: -webkit-optimize-contrast;
}

/* hack that prevents hw layers "stretching" when loading new tiles */
.leaflet-safari .leaflet-tile-container {
	width: 1600px;
	height: 1600px;
	-webkit-transform-origin: 0 0;
}

.leaflet-marker-icon,
.leaflet-marker-shadow {
	display: block;
}

/* .leaflet-container svg: reset svg max-width decleration shipped in Joomla! (joomla.org) 3.x */
/* .leaflet-container img: map is broken in FF if you have max-width: 100% on tiles */
.leaflet-container .leaflet-overlay-pane svg {
	max-width: none !important;
	max-height: none !important;
}

.leaflet-container .leaflet-marker-pane img,
.leaflet-container .leaflet-shadow-pane img,
.leaflet-container .leaflet-tile-pane img,
.leaflet-container img.leaflet-image-layer,
.leaflet-container .leaflet-tile {
	max-width: none !important;
	max-height: none !important;
	width: auto;
	padding: 0;
}

.leaflet-container img.leaflet-tile {
	/* See: https://bugs.chromium.org/p/chromium/issues/detail?id=600120 */
	mix-blend-mode: plus-lighter;
}

.leaflet-container.leaflet-touch-zoom {
	-ms-touch-action: pan-x pan-y;
	touch-action: pan-x pan-y;
}
.leaflet-container.leaflet-touch-drag {
	-ms-touch-action: pinch-zoom;
	/* Fallback for FF which doesn't support pinch-zoom */
	touch-action: none;
	touch-action: pinch-zoom;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
	-ms-touch-action: none;
	touch-action: none;
}

.leaflet-container {
	-webkit-tap-highlight-color: transparent;
}

.leaflet-container a {
	-webkit-tap-highlight-color: rgba(51, 181, 229, 0.4);
}

.leaflet-tile {
	filter: inherit;
	visibility: hidden;
}

.leaflet-tile-loaded {
	visibility: inherit;
}

.leaflet-zoom-box {
	width: 0;
	height: 0;
	-moz-box-sizing: border-box;
  box-sizing: border-box;
	z-index: 800;
}

/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */
.leaflet-overlay-pane svg {
	-moz-user-select: none;
}

.leaflet-pane         { z-index: 400; }
.leaflet-tile-pane    { z-index: 200; }
.leaflet-overlay-pane { z-index: 400; }
.leaflet-shadow-pane  { z-index: 500; }
.leaflet-marker-pane  { z-index: 600; }
.leaflet-tooltip-pane   { z-index: 650; }
.leaflet-popup-pane   { z-index: 700; }
.leaflet-map-pane canvas { z-index: 100; }
.leaflet-map-pane svg    { z-index: 200; }
.leaflet-vml-shape {
	width: 1px;
	height: 1px;
}

.lvml {
	behavior: url(#default#VML);
	display: inline-block;
	position: absolute;
}

/* control positioning */
.leaflet-control {
	position: relative;
	z-index: 800;
	pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
	pointer-events: auto;
}

.leaflet-top,
.leaflet-bottom {
	position: absolute;
	z-index: 1000;
	pointer-events: none;
}

.leaflet-top {
	top: 0;
}

.leaflet-right {
	right: 0;
}

.leaflet-bottom {
	bottom: 0;
}

.leaflet-left {
	left: 0;
}

.leaflet-control {
	float: left;
	clear: both;
}

.leaflet-right .leaflet-control {
	float: right;
}

.leaflet-top .leaflet-control {
	margin-top: 10px;
}

.leaflet-bottom .leaflet-control {
	margin-bottom: 10px;
}

.leaflet-left .leaflet-control {
	margin-left: 10px;
}

.leaflet-right .leaflet-control {
	margin-right: 10px;
}

/* zoom and fade animations */
.leaflet-fade-anim .leaflet-popup {
	opacity: 0;
	-webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
	opacity: 1;
}

.leaflet-zoom-animated {
	-webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
}

svg.leaflet-zoom-animated {
	will-change: transform;
}
.leaflet-zoom-anim .leaflet-zoom-animated {
	-webkit-transition: -webkit-transform 0.25s cubic-bezier(0,0,0.25,1);
  -moz-transition:    -moz-transform 0.25s cubic-bezier(0,0,0.25,1);
  transition:         transform 0.25s cubic-bezier(0,0,0.25,1);
}

.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile {
	-webkit-transition: none;
  -moz-transition: none;
  transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
	visibility: hidden;
}

/* cursors */
.leaflet-interactive {
	cursor: pointer;
}

.leaflet-grab {
	cursor: -webkit-grab;
	cursor:    -moz-grab;
	cursor:         grab;
}

.leaflet-crosshair,
.leaflet-crosshair .leaflet-interactive {
	cursor: crosshair;
}

.leaflet-popup-pane,
.leaflet-control {
	cursor: auto;
}

.leaflet-dragging .leaflet-grab,
.leaflet-dragging .leaflet-grab .leaflet-interactive,
.leaflet-dragging .leaflet-marker-draggable {
	cursor: move;
	cursor: -webkit-grabbing;
	cursor:    -moz-grabbing;
	cursor:         grabbing;
}

/* marker & overlays interactivity */
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-image-layer,
.leaflet-pane > svg path,
.leaflet-tile-container {
	pointer-events: none;
}

.leaflet-marker-icon.leaflet-interactive,
.leaflet-image-layer.leaflet-interactive,
.leaflet-pane > svg path.leaflet-interactive,
svg.leaflet-image-layer.leaflet-interactive path {
	pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
	pointer-events: auto;
}

/* visual tweaks */
.leaflet-container {
	background: #ddd;
	outline-offset: 1px;
}

.leaflet-container a {
	color: #0078A8;
}

.leaflet-zoom-box {
	border: 2px dotted #38f;
	background: rgba(255,255,255,0.5);
}

/* general typography */
.leaflet-container {
	font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
	font-size: 12px;
	font-size: 0.75rem;
	line-height: 1.5;
}

/* general toolbar styles */
.leaflet-bar {
	box-shadow: 0 1px 5px rgba(0,0,0,0.65);
	border-radius: 4px;
}

.leaflet-bar a {
	background-color: #fff;
	border-bottom: 1px solid #ccc;
	width: 26px;
	height: 26px;
	line-height: 26px;
	display: block;
	text-align: center;
	text-decoration: none;
	color: black;
}

.leaflet-bar a,
.leaflet-control-layers-toggle {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	display: block;
}

.leaflet-bar a:hover,
.leaflet-bar a:focus {
	background-color: #f4f4f4;
}

.leaflet-bar a:first-child {
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}

.leaflet-bar a:last-child {
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	border-bottom: none;
}

.leaflet-bar a.leaflet-disabled {
	cursor: default;
	background-color: #f4f4f4;
	color: #bbb;
}

.leaflet-touch .leaflet-bar a {
	width: 30px;
	height: 30px;
	line-height: 30px;
}

.leaflet-touch .leaflet-bar a:first-child {
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
}

.leaflet-touch .leaflet-bar a:last-child {
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
}

/* zoom control */
.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
	font: bold 18px 'Lucida Console', Monaco, monospace;
	text-indent: 1px;
}

.leaflet-touch .leaflet-control-zoom-in, .leaflet-touch .leaflet-control-zoom-out  {
	font-size: 22px;
}

/* layers control */
.leaflet-control-layers {
	box-shadow: 0 1px 5px rgba(0,0,0,0.4);
	background: #fff;
	border-radius: 5px;
}

.leaflet-control-layers-toggle {
	background-image: url(images/layers.png);
	width: 36px;
	height: 36px;
}

.leaflet-retina .leaflet-control-layers-toggle {
	background-image: url(images/layers-2x.png);
	background-size: 26px 26px;
}

.leaflet-touch .leaflet-control-layers-toggle {
	width: 44px;
	height: 44px;
}

.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
	display: none;
}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
	display: block;
	position: relative;
}

.leaflet-control-layers-expanded {
	padding: 6px 10px 6px 6px;
	color: #333;
  background: #fff;
}

.leaflet-control-layers-scrollbar {
	overflow-y: scroll;
	overflow-x: hidden;
	padding-right: 5px;
}

.leaflet-control-layers-selector {
	margin-top: 2px;
	position: relative;
	top: 1px;
}

.leaflet-control-layers label {
	display: block;
	font-size: 13px;
	font-size: 1.08333em;
}

.leaflet-control-layers-separator {
	height: 0;
	border-top: 1px solid #ddd;
	margin: 5px -10px 5px -6px;
}

/* Default icon URLs */
.leaflet-default-icon-path { /* used only in path-guessing heuristic, see L.Icon.Default */
	background-image: url(images/marker-icon.png);
}

/* attribution and scale controls */
.leaflet-container .leaflet-control-attribution {
	background: #fff;
	background: rgba(255, 255, 255, 0.8);
	margin: 0;
}

.leaflet-control-attribution,
.leaflet-control-scale-line {
	padding: 0 5px;
	color: #333;
	line-height: 1.4;
}

.leaflet-control-attribution a {
	text-decoration: none;
}

.leaflet-control-attribution a:hover,
.leaflet-control-attribution a:focus {
	text-decoration: underline;
}

.leaflet-attribution-flag {
	display: inline !important;
	vertical-align: baseline !important;
	width: 1em;
	height: 0.6669em;
}

.leaflet-left .leaflet-control-scale {
	margin-left: 5px;
}

.leaflet-bottom .leaflet-control-scale {
	margin-bottom: 5px;
}

.leaflet-control-scale-line {
	border: 2px solid #777;
	border-top: none;
	line-height: 1.1;
	padding: 2px 5px 1px;
	white-space: nowrap;
	-moz-box-sizing: border-box;
  box-sizing: border-box;
	background: rgba(255, 255, 255, 0.8);
	text-shadow: 1px 1px #fff;
}

.leaflet-control-scale-line:not(:first-child) {
	border-top: 2px solid #777;
	border-bottom: none;
	margin-top: -2px;
}

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
	border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
	box-shadow: none;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
	border: 2px solid rgba(0,0,0,0.2);
	background-clip: padding-box;
}

/* popup */
.leaflet-popup {
	position: absolute;
	text-align: center;
	margin-bottom: 20px;
}

.leaflet-popup-content-wrapper {
	padding: 1px;
	text-align: left;
	border-radius: 12px;
}

.leaflet-popup-content {
	margin: 13px 24px 13px 20px;
	line-height: 1.3;
	font-size: 13px;
	font-size: 1.08333em;
	min-height: 1px;
}

.leaflet-popup-content p {
	margin: 17px 0;
	margin: 1.3em 0;
}

.leaflet-popup-tip-container {
	width: 40px;
	height: 20px;
	position: absolute;
	left: 50%;
	margin-top: -1px;
	margin-left: -20px;
	overflow: hidden;
	pointer-events: none;
}

.leaflet-popup-tip {
	width: 17px;
	height: 17px;
	padding: 1px;
	margin: -10px auto 0;
	pointer-events: auto;
	-webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
	background: white;
	color: #333;
	box-shadow: 0 3px 14px rgba(0,0,0,0.4);
}

.leaflet-container a.leaflet-popup-close-button {
	position: absolute;
	top: 0;
	right: 0;
	border: none;
	text-align: center;
	width: 24px;
	height: 24px;
	font: 16px/24px Tahoma, Verdana, sans-serif;
	color: #757575;
	text-decoration: none;
	background: transparent;
}

.leaflet-container a.leaflet-popup-close-button:hover,
.leaflet-container a.leaflet-popup-close-button:focus {
	color: #585858;
}

.leaflet-popup-scrolled {
	overflow: auto;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
	-ms-zoom: 1;
}

.leaflet-oldie .leaflet-popup-tip {
	width: 24px;
	margin: 0 auto;
	-ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
	filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678);
}

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
	border: 1px solid #999;
}

/* div icon */
.leaflet-div-icon {
	background: #fff;
	border: 1px solid #666;
}

/* Tooltip */
/* Base styles for the element that has a tooltip */
.leaflet-tooltip {
	position: absolute;
	padding: 6px;
	background-color: #fff;
	border: 1px solid #fff;
	border-radius: 3px;
	color: #222;
	white-space: nowrap;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	pointer-events: none;
	box-shadow: 0 1px 3px rgba(0,0,0,0.4);
}

.leaflet-tooltip.leaflet-interactive {
	cursor: pointer;
	pointer-events: auto;
}

.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
	position: absolute;
	pointer-events: none;
	border: 6px solid transparent;
	background: transparent;
	content: "";
}

/* Directions */

.leaflet-tooltip-bottom {
	margin-top: 6px;
}

.leaflet-tooltip-top {
	margin-top: -6px;
}

.leaflet-tooltip-bottom:before,
.leaflet-tooltip-top:before {
	left: 50%;
	margin-left: -6px;
}

.leaflet-tooltip-top:before {
	bottom: 0;
	margin-bottom: -12px;
	border-top-color: #fff;
}

.leaflet-tooltip-bottom:before {
	top: 0;
	margin-top: -12px;
	margin-left: -6px;
	border-bottom-color: #fff;
}

.leaflet-tooltip-left {
	margin-left: -6px;
}

.leaflet-tooltip-right {
	margin-left: 6px;
}

.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
	top: 50%;
	margin-top: -6px;
}

.leaflet-tooltip-left:before {
	right: 0;
	margin-right: -12px;
	border-left-color: #fff;
}

.leaflet-tooltip-right:before {
	left: 0;
	margin-left: -12px;
	border-right-color: #fff;
}
/* Slick carousel */
.slick-arrow:before {
	display: none;
}

/* Printing */
@media print {
	/* Prevent printers from removing background-images of controls. */
	.leaflet-control {
		-webkit-print-color-adjust: exact;
		print-color-adjust: exact;
  }
}
`;

export const blackTextLink = theme =>
  css({
    color: theme.gray100,
  });

export default theme => injectGlobal`
  // color: ${theme.textLightPrimary};
`;

export const mb = mar =>
  css({
    marginBottom: mar.lg,
    [Mobile]: { marginBottom: mar.span },
  });

export const mr = mar =>
  css({
    [Desktop]: { marginRight: mar.lg || mar.xl || mar.xxl },
    [Mobile]: { marginRight: mar.md || mar.xs },
  });

export const ml = mar =>
  css({
    [Desktop]: { marginLeft: mar.lg || mar.xl || mar.xxl },
    [Mobile]: { marginLeft: mar.md || mar.xs },
  });

export const mt = mar =>
  css({
    [Desktop]: { marginTop: mar.lg || mar.xl || mar.xxl },
    [Mobile]: { marginTop: mar.md || mar.xs },
  });
