import React, { useState } from 'react';
import { useLocations } from '@oneecosystem/dealshaker-core';
import { useTranslations } from '@veraio/strank';
import { useDeepEffect } from 'components/UIExternal';
import { DealsCarousel } from 'components/shared';
import { dealsSliderType } from 'enums';
import useError from 'services/errorHandling/useError';
import { getRandomDeals } from 'services/api/dealsService';

const DealsOfTheDay = () => {
  const { shippingTo, shippingFrom } = useLocations();
  const { getText } = useTranslations();
  const { setError } = useError();
  const [deals, setDeals] = useState(null);

  useDeepEffect(() => {
    fetchDeals();
  }, [shippingFrom, shippingTo]);

  const fetchDeals = async () => {
    const [res, err] = await getRandomDeals({
      from: shippingFrom?.map(el => el?.id),
      to: shippingTo?.map(el => el?.id),
      promotionType: dealsSliderType?.dealsOfTheDay,
      pageSize: 10,
    });
    err ? setError(err) : setDeals(res?.items);
  };

  return (
    <div className="content-container deals-of-the-day">
      <DealsCarousel deals={deals} title={getText('promoteDealsOfTheDay')} hasCountdown />
    </div>
  );
};

export default DealsOfTheDay;
