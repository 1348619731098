import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isTokenValid } from '@oneecosystem/authenticate';
import { mb } from 'assets/css/globalCSS';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import FeatherIcon from 'feather-icons-react';
import { Button } from 'components/UIExternal';
import { DealPrice } from 'components/shared';
import { deliveryMethodSelect } from 'enums';
import useError from 'services/errorHandling/useError';
import { addDealToWishlist, deleteDealFromWishlist } from 'services/api/wishlist';
import placeholderThumbnail from 'assets/images/placeholderThumbnail.svg';
import DiscountStamp from '../DiscountStamp';
import {
  featuredDeal,
  thumbnailContainer,
  dealDiscountStamp,
  StarRatings,
  ratingsCountLabel,
  ratingsCountLink,
  addWishlistBtn,
} from './styles';

const FeaturedDeal = ({ deal }) => {
  const theme = useTheme();
  const { getText, getDynamicTranslation } = useTranslations();
  const { setError } = useError();
  const { id, name, media, discount, isInWishList, rating, reviewsCount } = deal;
  const isLoggedIn = isTokenValid();
  const [addedToWishlist, setAddedToWishlist] = useState(isInWishList);

  const handleDealWishList = async wishListRequest => {
    const [, err] = await wishListRequest(id);
    err ? setError(err) : setAddedToWishlist(prev => !prev);
  };

  const manageWishlist = () => {
    addedToWishlist ? handleDealWishList(deleteDealFromWishlist) : handleDealWishList(addDealToWishlist);
  };

  const thumbnailUrl =
    media?.typeId === 3 ? `https://img.youtube.com/vi/${media?.url}/0.jpg` : media?.url || placeholderThumbnail;

  return (
    <div className={featuredDeal(theme)}>
      <div className={thumbnailContainer}>
        <img className="featured-deal-image" alt="Featured Deal" src={thumbnailUrl} />
        {discount > 0 && <DiscountStamp deal={deal} className={dealDiscountStamp} />}
      </div>
      <div>
        <h3 className="featured-deal-title">{getDynamicTranslation(deal?.dealDetails)?.title}</h3>
        <div className={mb({ lg: 20, span: 20 })}>
          <StarRatings theme={theme} value={rating} disabled />
          {reviewsCount > 0 ? (
            <Button type="link" small className={ratingsCountLink(theme)} linkTo={`/deal/${name}/reviews`}>
              {reviewsCount}
            </Button>
          ) : (
            <label className={ratingsCountLabel(theme)}>{reviewsCount}</label>
          )}
          <i className={`${deliveryMethodSelect[deal.deliveryMethodId]?.icon} featured-deal-delivery-image`} />
          <h5 className="featured-deal-delivery-text">{getText(deliveryMethodSelect[deal.deliveryMethodId]?.label)}</h5>
        </div>
        <DealPrice deal={deal} showSavings dataId="featured-deal-price-panel" />
        <div className="flex space-between">
          <Button type="primary" small linkTo={`/deal/${name}`}>
            {getText('viewDetails')}
          </Button>
          {isLoggedIn && (
            <Button type="link" className={addWishlistBtn(theme)} onClick={manageWishlist}>
              <FeatherIcon
                icon="heart"
                size={17}
                stroke={addedToWishlist ? theme.white : theme.gray300}
                fill={addedToWishlist ? theme.red : 'transparent'}
              />
              {getText(addedToWishlist ? 'addedToWishlist' : 'addToWishlist')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

FeaturedDeal.propTypes = {
  deal: PropTypes.object,
};

export default FeaturedDeal;
