import { css } from 'emotion';

export const orderDetailsDealsContainer = css({
  overflowY: 'auto',
  maxHeight: '20rem',

  '& .order-details-deal-container': {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    '&:not(:last-of-type)': {
      marginBottom: '1rem',
    },

    '& .order-details-deal-info': {
      flex: 5,
      display: 'flex',
      alignItems: 'center',

      '& .order-details-deal-image': {
        maxWidth: '6rem',
        maxHeight: '6rem',
        borderRadius: '10px',
        marginRight: '1rem',
        aspectRatio: '1 / 1',
        objectFit: 'cover',
      },
    },

    '& .order-details-deal-quantity': {
      flex: 1,
    },

    '& .order-details-deal-price': {
      flex: 2,
    },
  },
});

export const orderDetailsDealLink = css({
  fontSize: '1.1rem',
  padding: '0',
  whiteSpace: 'normal',
});
