import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import Slider from 'react-slick';
import FeatherIcon from 'feather-icons-react';
import { isDesktop } from 'react-device-detect';
import { isNil } from '@veraio/core';
import { CarouselSkeleton } from 'components/ui';
import { CountdownTimer } from 'components/UIExternal';
import Deal from 'components/deal/Deal';
import { headingPanel, arrowButton, wrapper, headingTitle } from './styles';

const arrowSettings = (position, theme) => ({
  size: 40,
  className: arrowButton(position),
  stroke: theme.gray200,
  strokeWidth: 1,
});

const arrowGeneral = slidesPerPage => ({
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: slidesPerPage,
  slidesToScroll: 1,
  arrows: isDesktop,
});

const DealsCarousel = ({ deals, title, hasCountdown, pageSize }) => {
  const theme = useTheme();
  const slidesPerPage = isDesktop ? pageSize ?? 5 : 1;

  const arrow = position => (
    <div>
      <FeatherIcon icon={`chevron-${position}`} {...arrowSettings(position, theme)} />;
    </div>
  );

  const settings = {
    ...arrowGeneral(slidesPerPage),
    nextArrow: arrow('right', theme),
    prevArrow: arrow('left', theme),
  };

  return (
    <div className="deals-carousel">
      <div className={headingPanel}>
        <h2 className={headingTitle}>{title}</h2>
        {hasCountdown && <CountdownTimer />}
      </div>
      {isNil(deals) && <CarouselSkeleton numberOfDealsPerSlide={4} />}
      <div className={`${wrapper(isDesktop && deals?.length < slidesPerPage + 1)} row`}>
        <Slider {...settings}>
          {deals?.map(deal => (
            <div className="col" key={deal.id}>
              <Deal deal={deal} dataId="deal-carousel-item" />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

DealsCarousel.propTypes = {
  deals: PropTypes.array,
  title: PropTypes.string,
  hasCountdown: PropTypes.bool,
  pageSize: PropTypes.number,
};

export default DealsCarousel;
