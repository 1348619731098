import { css } from 'emotion';
import { Mobile } from 'assets/css/breakpoints';

export const panel = theme =>
  css({
    border: `2px solid ${theme.gray500}`,
    borderRadius: 3,
    padding: '1em',
  });

export const title = css({
  fontSize: '1.375rem',
  [Mobile]: { fontSize: '1.1875rem' },
  fontWeight: 700,
  marginBottom: '0.8125em',
});

export const wrapper = hideClones =>
  css({
    '& .slick-cloned': { display: hideClones && 'none !important' },
    '& .slick-track': { margin: hideClones && '0 !important' },
    '& .slick-list': {
      margin: '0 -0.9375em',
      padding: '0px !important',
    },
    '& .slick-slide > div': {
      padding: ' 0 0.9375em',
    },
  });

export const arrowButton = position =>
  css({
    position: 'absolute',
    top: '50%',
    cursor: 'pointer',
    zIndex: 900,
    margin: position === 'right' ? '0 0 0 1.25em' : '0 0 0 -1.25em',
    left: position === 'left' ? '-22px' : 'auto',
  });
