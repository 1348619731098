import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { concatStrings } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { isTokenValid } from '@oneecosystem/authenticate';
import { Rate } from 'antd';
import { Button, DealPrice, Icon } from 'components';
import { formatDescriptiveDate } from 'utils';
import { setError, addDealToWishlist, deleteDealFromWishlist } from 'services';
import placeholderThumbnail from 'assets/images/placeholderThumbnail.svg';
import { ml } from 'assets/css/globalCSS';
import DiscountStamp from '../DiscountStamp';
import {
  dealDiscountStamp,
  favoriteIcon,
  dateAvailableLabel,
  starRatings,
  ratingsCountLabel,
  ratingsCountLink,
  dealContainer,
  dealCategoryContainer,
  categoryLabel,
} from './styles';

export const Deal = ({ deal, dataId, className }) => {
  const { getText, getDynamicTranslation } = useTranslations();
  const theme = useTheme();
  const isLoggedIn = isTokenValid();
  const { id, name, media, discount, isInWishList, dateAvailable, category, rating, reviewsCount } = deal;
  const [addedToWishlist, setAddedToWishlist] = useState(isInWishList);

  const handleDealWishList = async wishListRequest => {
    const [, err] = await wishListRequest(id);
    err ? setError(err) : setAddedToWishlist(prev => !prev);
  };

  const manageWishlist = e => {
    e.stopPropagation();
    e.preventDefault();

    addedToWishlist ? handleDealWishList(deleteDealFromWishlist) : handleDealWishList(addDealToWishlist);
  };

  const thumbnailUrl =
    media?.typeId === 3 ? `https://img.youtube.com/vi/${media?.url}/0.jpg` : media?.url || placeholderThumbnail;

  return (
    <div className={ml({ lg: 10, md: 10 })} {...(className && { className })}>
      <Button
        type="link"
        key={id}
        className={concatStrings(dealContainer(theme), 'deal-button-container')}
        linkTo={`/deal/${name}`}
      >
        <div className="deal-thumbnail-container">
          <img alt="Deal" src={thumbnailUrl} className="deal-thumbnail" />

          {discount > 0 && <DiscountStamp deal={deal} className={dealDiscountStamp} />}
          {isLoggedIn && (
            <Button type="link" small onClick={e => manageWishlist(e)} className={favoriteIcon(addedToWishlist, theme)}>
              <Icon iconName="las la-heart la-lg" />
            </Button>
          )}
        </div>
        <h5 className="deal-title">{getDynamicTranslation(deal?.dealDetails)?.title}</h5>
        {dateAvailable && (
          <p className={dateAvailableLabel(theme)}>
            {getText('dealAvailableOn', { date: formatDescriptiveDate(dateAvailable) })}
          </p>
        )}
        <DealPrice deal={deal} className="deal-price-panel" dataId={`${dataId}-deal-price-panel`} />
        <div className={dealCategoryContainer}>
          <span className={categoryLabel}>{getText(category)}</span>
          <div>
            <Rate className={starRatings(theme)} value={rating} disabled />
            {reviewsCount > 0 ? (
              <Button type="link" className={ratingsCountLink(theme)} linkTo={`/deal/${name}/reviews`}>
                {reviewsCount}
              </Button>
            ) : (
              <span className={ratingsCountLabel(theme)}>{reviewsCount}</span>
            )}
          </div>
        </div>
      </Button>
    </div>
  );
};

Deal.propTypes = {
  deal: PropTypes.object,
  dataId: PropTypes.string,
  className: PropTypes.string,
};

export default Deal;
