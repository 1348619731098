import React, { useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import moment from 'moment';
import { useLocations } from '@oneecosystem/dealshaker-core';
import { omit } from '@veraio/core';
import { getText } from '@veraio/strank';
import { Button, useDeepEffect } from 'components/UIExternal';
import { DealsCarousel } from 'components/shared';
import useError from 'services/errorHandling/useError';
import { getRecommendedDeals } from 'services/api/dealsService';
import { Routes } from 'enums';

const RECOMMENDED_DEALS_PAGING = {
  1: { pageSize: 10, pageNumber: 4 },
  2: { pageSize: 20, pageNumber: 2, skip: 5 },
  3: { pageSize: 10, pageNumber: 3 },
  4: { pageSize: 15, pageNumber: 2 },
  5: { pageSize: 10, pageNumber: 2 },
  6: { pageSize: 15, pageNumber: 1, skip: 5 },
  7: { pageSize: 10, pageNumber: 1 },
}[moment().isoWeekday()];

const RecommendedDeals = () => {
  const { shippingTo, shippingFrom } = useLocations();
  const { setError } = useError();
  const [deals, setDeals] = useState(null);

  useDeepEffect(() => {
    fetchDeals();
  }, [shippingFrom, shippingTo]);

  const fetchDeals = async () => {
    const [res, err] = await getRecommendedDeals(omit(RECOMMENDED_DEALS_PAGING, ['skip']));
    const startIndex = RECOMMENDED_DEALS_PAGING?.skip ?? 0;
    err ? setError(err) : setDeals(res?.items.slice(startIndex, startIndex + 10));
  };

  return deals?.length > 0 ? (
    <div className="content-container recommended-deals">
      <DealsCarousel title={getText('recommendedDeals')} deals={deals} pageSize={5} />
      <Button type="link" small linkTo={`${Routes.dealListingPage}?isRecommended=true`}>
        {getText('allRecommendedDeals')}
        <FeatherIcon icon="chevron-right" size={15} />
      </Button>
    </div>
  ) : (
    <></>
  );
};

export default RecommendedDeals;
