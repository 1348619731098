import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Rate, Checkbox } from 'antd';
import { useTranslations } from '@veraio/strank';
import DealPrice from 'components/shared/DealPrice';
import placeholderThumbnail from 'assets/images/placeholderThumbnail.svg';
import { Button } from '../../UIExternal';
import {
  dealTitle,
  thumbnail,
  baseDealCard,
  starRatings,
  ratingsCountLabel,
  thumbnailContainer,
  checkbox,
  connectedDealPreview,
} from './styles';

const BaseDeal = ({ deal, isSelectable, selectedDeals, setSelectedDeals }) => {
  const theme = useTheme();
  const { getDynamicTranslation } = useTranslations();
  const { media, name, title, rating, reviewsCount } = deal;

  const handleTitle = text => (text?.length > 15 ? `${text.slice(0, 15)}...` : text);

  return (
    <Button type="info" className={connectedDealPreview(isSelectable)}>
      <div className={baseDealCard}>
        <div className={thumbnailContainer}>
          <img className={thumbnail} src={media ? media.url : placeholderThumbnail} alt={name} />
          {isSelectable && (
            <Checkbox
              className={checkbox}
              checked={selectedDeals?.includes(deal.id)}
              onChange={e =>
                e.target.checked
                  ? setSelectedDeals(selectedDeals => [...selectedDeals, deal.id])
                  : setSelectedDeals(selectedDeals => selectedDeals.filter(id => id !== deal.id))
              }
            />
          )}
        </div>
        <p className={dealTitle}>{handleTitle(getDynamicTranslation(title)?.title)}</p>
        <DealPrice deal={deal} dataId="connected-deal-price-panel" />
        <div>
          <Rate className={starRatings(theme)} value={rating} disabled />
          <label className={ratingsCountLabel(theme)}>{reviewsCount}</label>
        </div>
      </div>
    </Button>
  );
};

BaseDeal.propTypes = {
  deal: PropTypes.object,
  isSelectable: PropTypes.bool,
  selectedDeals: PropTypes.array,
  setSelectedDeals: PropTypes.func,
};

export default BaseDeal;
