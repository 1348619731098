import { css, cx, keyframes } from 'emotion';
import { commonStyles } from '../../ui/CommonStyles';

const textColorMap = theme => ({
  primary: theme.gray100,
  secondary: theme.white,
  default: theme.gray100,
  danger: theme.white,
  success: theme.white,
  warning: theme.gray100,
  link: theme.blue2,
});

const backgroundColorMap = theme => ({
  primary: theme.primary_yellow,
  secondary: theme.primary_blue,
  default: theme.white,
  danger: theme.red,
  success: theme.green2,
  warning: theme.warning,
  info: theme.skyblue,
  link: 'transparent',
});

const borderColorMap = theme => ({
  default: theme.gray500,
  link: 'transparent',
});

const hoverBackgroundColorMap = theme => ({
  primary: theme.primary_hover_btn,
  secondary: theme.primary_blue_hover,
  default: theme.default_hover,
  danger: theme.red_dark,
  success: theme.green,
  warning: theme.warningLight,
  info: theme.skyblue_hover,
  link: 'transparent',
});

const hoverBorderColorMap = theme => ({
  default: theme.gray300,
  link: 'transparent',
});

export const buttonContainer = (props, isLoading) => theme => {
  const {
    type = 'primary',
    disabled,
    color,
    borderColor,
    backColor,
    hoverBackColor,
    hoverBorderColor,
    fullWidth,
    small,
    className,
  } = props;

  const backgroundColor = theme[backColor] ?? backgroundColorMap(theme)[type] ?? theme.primary_yellow;
  const hoverBackgroundColor = theme[hoverBackColor] ?? hoverBackgroundColorMap(theme)[type] ?? theme.primary_hover_btn;
  const textColor = theme[color] ?? textColorMap(theme)[type] ?? theme.gray100;
  const borderColorDefault = theme[borderColor] ?? borderColorMap(theme)[type] ?? backgroundColor;
  const borderColorHover = theme[hoverBorderColor] ?? hoverBorderColorMap(theme)[type] ?? hoverBackgroundColor;
  const isLink = type === 'link';
  // const checkbox =

  return cx(
    css([
      {
        cursor: disabled ? 'not-allowed' : 'pointer',
        padding: small ? '0.5em 0.8em' : '1em 1.25em',
        width: fullWidth ? '100%' : 'auto',
        minHeight: small ? '1.875rem' : '2.5rem',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: textColor,
        borderRadius: isLink ? 0 : 6,
        lineHeight: 1,
        fontWeight: isLink ? 400 : 600,
        border: `1px solid ${borderColorDefault}`,
        opacity: isLoading || disabled ? 0.6 : 1,
        backgroundColor,
        userSelect: 'none',
        whiteSpace: 'nowrap',
        transition:
          'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out, width .4s ease-in-out',

        '&:hover': {
          borderColor: borderColorHover,
          backgroundColor: hoverBackgroundColor,
        },

        '& > i': { marginRight: '0.375em' },
      },
      (disabled || isLoading) && {
        '& *': { pointerEvents: 'none' },
      },
      ...commonStyles(props),
    ]),
    className,
  );
};

export const spinnerBtn = css({
  '&.ant-spin': {
    position: 'relative',
    '& .ant-spin-dot': {
      position: 'absolute',
      left: '0.3125em',
      top: '-0.5em',
      bottom: 0,
    },
  },
});

export const checkButtonContainer = (isDisabled, className) =>
  css(
    {
      display: 'inline-flex',
      alignItems: 'center',
      cursor: isDisabled ? 'default' : 'pointer',
    },
    className,
  );

export const switchButtonContainer = (hasLabel, isChecked, props) => theme => {
  const { className } = props;

  return css([
    {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      borderRadius: props?.radio ? '50%' : 5,
      padding: props?.radio ? 1 : 2,
      backgroundColor:
        isChecked && !props?.radio ? theme.blue2 : isChecked && props?.radio ? theme.blue : 'transparent',
      transition: 'all 0.4s ease-in-out',
      width: 22,
      height: 22,
    },

    hasLabel && {
      '& > .label': {
        display: 'inline-flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        marginLeft: '0.8rem',
      },
    },
    className,
  ]);
};

export const checkboxContainer = (isDisabled, className, checkbox, radio, theme) =>
  css({
    border: `1px solid ${theme.gray200}`,
    height: '1.2rem',
    width: '1.2rem',
    borderRadius: radio && 20,
    marginRight: '.5em',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: isDisabled ? 'default' : 'pointer',
    backgroundColor: isDisabled ? theme.white : theme.white,
    '&:before': {
      content: '""',
      display: 'inline-block',
      visibility: checkbox ? 'visible' : 'hidden',
      ...(radio
        ? {
            visibility: checkbox ? 'visible' : 'hidden',
            width: '.7rem',
            height: '.7rem',
            borderRadius: 20,
            background: theme.green2,
          }
        : {
            visibility: checkbox ? 'visible' : 'hidden',
            width: '.4rem',
            height: '.9rem',
            margin: '0 auto 0.2rem',
            background: 'transparent',
            borderRight: `2px solid ${isDisabled ? theme.gray300 : theme.primary_blue}`,
            borderBottom: `2px solid ${isDisabled ? theme.gray300 : theme.primary_blue}`,
            transform: 'rotate(45deg)',
          }),
    },
  });

export const switchToggle = (size, color, isChecked) => theme => {
  const backgroundColor = isChecked ? theme[color] ?? theme.primary_blue : theme.gray300;

  return css({
    cursor: 'pointer',
    width: size.width * 2,
    height: size.width,
    display: 'inline-flex',
    position: 'relative',
    backgroundColor: theme.skyblue,
    textAlign: 'left',
    borderRadius: size.width,
    boxSizing: 'border-box',
    perspective: 300,
    border: `1px solid ${theme.skyblue}`,
    transition: 'border .8s ease-in-out',
    marginRight: '0.8rem',
    flexShrink: 0,

    '&:before': {
      content: "''",
      width: size.smallWidth,
      height: size.smallWidth,
      position: 'absolute',
      right: size.padding,
      top: size.padding - 1,
      display: 'inline-flex',
      textAlign: 'center',
      borderRadius: size.smallWidth,
      transition: 'all 1s ease-in-out',
      transformOrigin: '0% 50%',
      transform: 'rotateY(0deg)',
      animation: `${
        isChecked ? switchButtonEndAnimation(size) : switchButtonStartAnimation(size)
      } 0.6s ease-in-out forwards`,
      backgroundColor,
    },
  });
};

export const switchContainer = css({
  '.switch-content-container': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '.label-left': {
      marginRight: '1.2rem',
    },

    '.label-right': {
      marginLeft: '2rem',
    },
  },
});

const switchButtonStartAnimation = size => keyframes`
  0% {
    right: ${size.padding}px;
    width: ${size.smallWidth};
  }
  25% {
    right: ${size.padding}px;
    width: calc(100% - ${size.padding * 2}px);
  }
  50% {
    right: auto;
    width: calc(100% - ${size.padding * 2}px);
    left: ${size.padding}px;
  }
  100% {
    left: ${size.padding}px;
    width: ${size.smallWidth};
  }
`;

const switchButtonEndAnimation = size => keyframes`
  0% {
    left: ${size.padding}px;
    width: ${size.smallWidth};
  }
  25% {
    left: ${size.padding}px;
    width: calc(100% - ${size.padding * 2}px);
  }
  50% {
    right: ${size.padding}px;
    width: calc(100% - ${size.padding * 2}px);
    left: auto;
  }
  100% {
    right: ${size.padding}px;
    width: ${size.smallWidth};
  }
`;
