import React, { useEffect, useState } from 'react';
import { useTheme } from 'emotion-theming';
import { isNil } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { useLocations } from '@oneecosystem/dealshaker-core';
import { isDesktop } from 'react-device-detect';
import FeatherIcon from 'feather-icons-react';
import Slider from 'react-slick';
import Deal from 'components/deal/Deal';
import { CarouselSkeleton } from 'components/ui';
import useError from 'services/errorHandling/useError';
import { getBestSellingDeals } from 'services/api/dealsService';
import { panel, title, arrowButton, wrapper } from './styles';

const arrowSettings = (position, theme) => ({
  size: 40,
  className: arrowButton(position),
  stroke: theme.gray200,
  strokeWidth: 1,
});

const arrowGeneral = slidesPerPage => ({
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: slidesPerPage,
  slidesToScroll: 1,
  arrows: isDesktop,
});

const BestSellingDeals = () => {
  const { getText } = useTranslations();
  const { shippingTo, shippingFrom } = useLocations();
  const theme = useTheme();
  const { setError } = useError();
  const slidesPerPage = isDesktop ? 5 : 1;
  const [deals, setDeals] = useState(null);

  useEffect(() => {
    fetchDeals();
  }, [shippingFrom, shippingTo]);

  const fetchDeals = async () => {
    const [res, err] = await getBestSellingDeals({
      from: shippingFrom?.map(el => el?.id),
      to: shippingTo?.map(el => el?.id),
      pageSize: 8,
    });
    err ? setError(err) : setDeals(res?.items);
  };

  const arrow = position => (
    <div>
      <FeatherIcon icon={`chevron-${position}`} {...arrowSettings(position, theme)} />;
    </div>
  );

  const settings = {
    ...arrowGeneral(slidesPerPage),
    nextArrow: arrow('right', theme),
    prevArrow: arrow('left', theme),
  };

  return (
    <div className="content-container">
      <div className={panel(theme)}>
        <h2 className={title}>{getText('bestSellers')}</h2>
        {isNil(deals) && <CarouselSkeleton numberOfDealsPerSlide={4} />}
        <div className={wrapper(isDesktop && deals?.length < slidesPerPage + 1)}>
          <Slider {...settings}>
            {deals?.map(deal => (
              <Deal key={deal.id} deal={deal} dataId="best-selling" />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default BestSellingDeals;
