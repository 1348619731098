import { css } from 'emotion';
import { Desktop, Mobile } from 'assets/css/breakpoints';

export const miniCartPanelContainer = theme =>
  css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: theme.white,
    borderRadius: 4,
    padding: '0.75em',
    [Mobile]: {
      minWidth: '100%',
      left: '0 !important',
      '& .ant-popover-content': {
        width: '100%',
      },
    },
    [Desktop]: {
      maxWidth: '27rem',
    },
  });

export const cartItemsStyle = css({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '18.75rem',
  maxHeight: '21.875rem',
  overflowY: 'auto',
  overflowX: 'hidden',
  alignItems: 'flex-start',
});

export const proceedToCheckoutButton = css({
  marginTop: '1.5em',
  width: '100%',
  '& :hover': {
    backgroundColor: 'white',
  },
});

export const primaryProceedBtn = css({
  margin: '1.5em auto 0.75em',
});

export const badgeContainer = css({
  position: 'relative',
  padding: '0.5em 0.75em',
  cursor: 'pointer',
  display: 'flex',
  [Mobile]: {
    padding: '0.25em',
  },
});

export const badgeNumber = (theme, showNumber) =>
  css({
    position: 'absolute',
    top: 0,
    right: '0.3125em',
    color: theme.gray100,
    display: showNumber && 'none',
    backgroundColor: theme.primary_yellow,
    fontSize: '0.5rem',
    fontWeight: 700,
    borderRadius: 2,
    padding: '0 0.0625em',
  });

export const popOverClass = css({
  position: 'fixed',
  '& .ant-popover-inner-content': {
    padding: '0.25em',
  },
  [Mobile]: {
    width: '100%',
  },
});

export const titleRemoveAllButtonContainer = css({
  '& label': {
    fontSize: '1.125rem',
  },
  fontWeight: 'bold',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: '0 0.75em',
});

export const thumbnailContainer = css({
  position: 'relative',
  borderRadius: 8,
  maxWidth: '4rem',
  aspectRatio: '1 / 1',
  objectFit: 'cover',
});

export const dealEndDateWrapper = css({
  fontSize: '0.625rem',
  '.icon-Ends-on': {
    fontSize: '1.125rem',
    lineHeight: '1rem',
    marginRight: '0.5em',
  },
  '& .icon-Delivery-Type-Redeem-at-location': {
    fontSize: '1.25rem',
    marginRight: '0.25em',
  },
  '& .icon-Delivery-Type-Self-organized': {
    fontSize: '1.125rem',
    marginRight: '0.25em',
  },
  '& .icon-QuestionMark-dotted': {
    fontSize: '0.875rem',
    marginLeft: '0.1875em',
  },
});

export const dealContentContainer = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flex: 1,
  height: '100%',
  '& p': {
    fontSize: '0.6875rem',
  },
  '& .flex': {
    justifyContent: 'space-between',
  },
});

export const contentRowContainer = theme =>
  css({
    paddingTop: '1.5em',
    width: '100%',

    '& :hover': {
      backgroundColor: theme.gray600,
    },
  });

export const rowStyles = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  padding: '0 1em',
});

export const dealName = size =>
  css({
    fontSize: size,
    fontWeight: 600,
  });

export const priceTemplateStyles = css({
  margin: 'auto',
});
