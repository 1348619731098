import { css } from 'emotion';

export const thumbnailItemContainer = css({
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  aspectRatio: '1 / 1',
  objectFit: 'cover',
  borderRadius: 8,
});

export const itemContentContainer = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  flex: 1,
  height: '100%',
  '& .flex': {
    justifyContent: 'space-between',
  },
});

export const titleStyles = css({
  fontSize: '0.875rem',
  fontWeight: 500,
  marginBottom: '0.5em',
});

export const errorMessage = theme =>
  css({
    color: theme.red,
    fontSize: '0.75rem',
    margin: 0,
  });

export const cantDeliverMessage = theme =>
  css({
    color: theme.red,
    fontSize: '1rem',
    margin: '0.5em 0',
    padding: '0.75em',
    marginBottom: '0.75em',
    border: `1px solid ${theme.red}`,
    borderRadius: '8px',
  });

export const cantDeliverContainer = css({
  padding: '0.75em',
});
