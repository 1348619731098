import { css } from 'emotion';
import styled from '@emotion/styled';
import { BlackRating } from 'components/ui';
import { Phone } from 'assets/css/breakpoints';

export const featuredDeal = theme =>
  css({
    display: 'flex',
    color: theme.gray100,
    backgroundColor: theme.white,
    border: `1px solid ${theme.white}`,
    flexFlow: 'row nowrap',
    alignItems: 'center',
    padding: '3em 10%',

    '&:hover': {
      backgroundColor: theme.white,
      border: `1px solid ${theme.gray400}`,
    },

    [Phone]: {
      flexFlow: 'column nowrap',
      padding: '1em',
    },

    '& .featured-deal-title': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: '-webkit-box',
      webkitLineClamp: '2',
      webkitBoxOrient: 'vertical',
      marginBottom: '0.3125em',
    },

    '& .featured-deal-delivery-image': {
      fontSize: '1.25rem',
      margin: '0 0.25em 0 3em',
      color: theme.gray200,
    },

    '& .featured-deal-delivery-text': {
      display: 'inline',
    },

    '& .featured-deal-image': {
      width: 320,
      height: 320,
      objectFit: 'cover',
      borderRadius: 4,
    },
  });

export const thumbnailContainer = css({
  position: 'relative',
  margin: '0 3em 0 0',
  flex: '0 0 320px',
  [Phone]: {
    margin: '0 0 1em 0',
  },
});

export const thumbnail = css({
  width: '100%',
  height: 'auto',
});

export const dealDiscountStamp = css({
  top: '0.5em',
  left: '0.5em',
  height: '2.5rem',
  width: '2.5rem',
});

export const StarRatings = styled(BlackRating)`
  margin-right: 0.625em;
  &.ant-rate {
    font-size: 0.75rem;
  }
`;

export const ratingsCountLink = theme =>
  css({
    fontSize: '0.8125rem',
    color: theme.gray300,
    textDecoration: 'underline',
  });

export const ratingsCountLabel = theme =>
  css({
    fontSize: '0.8125rem',
    color: theme.gray300,
  });

export const addWishlistBtn = theme =>
  css({
    color: theme.gray100,
    margin: '0.625em 0',
    '& svg': {
      marginRight: '0.25em',
    },
  });
