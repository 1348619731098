import { css } from 'emotion';
import styled from '@emotion/styled';
import { Mobile } from 'assets/css/breakpoints';

export const DealDescription = styled.p`
  font-size: 1rem;
  line-height: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const popoverWrapper = theme =>
  css({
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: '0px !important',
    backgroundColor: theme.white,
    borderRadius: '4px',
    '& .ant-popover-inner-content': {
      padding: 0,
    },
  });

export const dealContainer = (theme, isWishlistDeal, isMobile) =>
  css({
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.gray100,
    minHeight: isWishlistDeal && !isMobile && '4.5em',
  });

export const dealTitle = css({
  fontWeight: 600,
  lineHeight: 1.5,
  whiteSpace: 'normal',
  wordBreak: 'break-all',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const mr12 = css({
  marginRight: '0.75em',
  width: '1.25rem',
  height: '1.25rem',
});

export const couponIcon = (isMobile, isVertical, isWishlistDeal) =>
  css({
    fontSize: '1.125rem',
    marginRight: '0.375em',
    marginLeft: !isMobile && !isVertical && !isWishlistDeal && '0.625em',
  });

// TODO: export as responsive image custom component
export const thumbnailContainer = theme =>
  css({
    position: 'relative',
    height: '100%',
    paddingBottom: '57%',
    overflow: 'hidden',
    backgroundColor: theme.gray500,
    marginRight: '1.5em',
    borderRadius: '4px',
    width: '100%',
  });

export const thumbnail = css({
  width: '100%',
  maxWidth: '200px',
  height: 'auto',
  aspectRatio: '1 / 1',
  objectFit: 'cover',
  margin: 'auto',
  borderRadius: 4,
});

export const favoriteIcon = theme =>
  css({
    color: theme.white,
    padding: 0,
    position: 'absolute',
    bottom: 0,
    right: '1.25em',
  });

export const link = (isWishlistDeal, theme) =>
  css({
    color: theme.gray100,
    whiteSpace: 'normal',
    display: 'block',
    padding: 0,
    width: '100%',
    marginBottom: !isWishlistDeal && '1.875em',
    '& .list-deal-user-rating-coupons': {
      flexWrap: 'wrap',
      '@media (max-width: 480px)': {
        '& > div:last-child > span': {
          marginLeft: '0',
        },
      },
    },
    '& .list-deal-user-rating': {
      flexWrap: 'wrap',
    },
  });

export const dealRatingWrapper = (theme, isVertical, isMobile) =>
  css({
    borderLeft: !isVertical && !isMobile && `1px solid ${theme.gray500}`,
    borderRight: !isVertical && !isMobile && `1px solid ${theme.gray500}`,
    marginTop: (isVertical || isMobile) && 8,
    paddingLeft: !isVertical && !isMobile && 8,
    flexBasis: (isVertical || isMobile) && '100%',
    paddingRight: '0.75em',
    marginRight: '1em',
  });

export const starRatings = theme =>
  css({
    marginRight: '0.75em !important',
    '&.ant-rate': {
      fontSize: '1rem',
      color: theme.gray100,
    },
  });

export const ratingsCountLink = theme =>
  css({
    fontSize: '0.8125rem',
    color: theme.gray200,
    textDecoration: 'underline',
  });

export const ratingsCountLabel = theme =>
  css({
    fontSize: '0.8125rem',
    color: theme.gray200,
  });

export const dealEndDateWrapper = (isVertical, isWishlistDeal) =>
  css({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'baseline',
    '.icon-Ends-on': {
      fontSize: '1rem',
      display: 'inline-block',
      lineHeight: '1rem',
      marginRight: !isVertical && !isWishlistDeal && '0.125em',
    },
    '& .delivery-icon': {
      fontSize: '1.25rem',
      display: 'inline-block',
      marginRight: !isVertical && !isWishlistDeal && '0.125em',
      marginLeft: !isVertical && !isWishlistDeal && '0.125em',
      [Mobile]: { display: 'initial', margin: '0 0.625em 0 0.9375em' },
    },
    '& .icon-Delivery-Type-Redeem-at-location:before,.icon-Delivery-Type-Online-Service:before': {
      marginBottom: '-0.1875em',
      display: 'inline-block',
      [Mobile]: { position: 'absolute', top: '0.3125em', left: '-0.8125em' },
    },
    '& .icon-Delivery-Type-Self-organized:before': {
      marginBottom: '-0.1875em',
      display: 'inline-block',
      [Mobile]: { position: 'absolute', top: '0.375em', left: '-0.8125em' },
    },
  });

export const verticalStyleWrapper = css({
  '& h2': {
    fontSize: '0.875rem',
    fontWeight: 700,
  },
  '& label': {
    fontSize: '0.75rem',
  },
  '& p': {
    marginBottom: 0,
    fontSize: '0.875rem',
  },
});

export const couponInfoWrapper = (isMobile, isVertical, isWishlistDeal, theme) =>
  css({
    color: theme.gray100,
    marginTop: (isMobile || isVertical || isWishlistDeal) && '0.5em',
    marginBottom: (isMobile || isVertical || isWishlistDeal) && '0.5em',
    '& span': {
      marginLeft: '0',
    },
  });

export const businessNameContainer = theme =>
  css({
    display: 'flex',
    alignItems: 'center',
    marginRight: '0.25em',
    color: theme.gray100,
  });

export const businessNameLink = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: 0,
  overflow: 'hidden',
  maxWidth: '5.625rem',
});
